html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html,
body,
#__next {
  height: 100%;
}

#__next {
  min-height: 100vh;
  background-color: #f5f5f5;
}

img {max-width:100%;}

.nav-item .nav-link {
  cursor: pointer;
}
