.wrapper {
  display: flex;
	flex-wrap: nowrap;
	height: 100vh;
	height: -webkit-fill-available;
	max-height: 100vh;
	overflow-x: auto;
	overflow-y: hidden;
}

.sidebar {
  display: flex;
  width: 280px;
}

.main {
  display: flex;
  padding: 1.5rem 1.5rem 0;
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
}

.footer {
  margin-top:auto;
  display: flex;
  padding: 1.5rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.logo {
  padding:0 1rem .5rem;
  // filter:invert(32%) sepia(13%) saturate(5723%) hue-rotate(179deg) brightness(96%) contrast(90%);
}